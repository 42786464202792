import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import BookAppointment from "../components/BookAppointment";
import SEOHelmet from "../SEOHelmet";
import OnlineModal from "../components/utility/OnlineModal";
import Testimonials from "../components/utility/Testimonials";

interface ServiceCardProps {
    image: string;
    title: string;
    description: string;
    slug: string;
}


interface BlogCardProps {
    image: string;
    tag: string;
    date: string;
    title: string;
    description: string;
    link: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ image, title, description, slug }) => {
    const navigate = useNavigate();

    const handleLearnMore = () => {
        navigate(`/services/${slug}`);
    };

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <div className="bg-white rounded-[40px] shadow-md p-12 lg:p-12 flex flex-col h-full items-center text-center">

            <img src={image} alt={title} className="w-full h-20 lg:h-36 object-contain rounded-lg mb-4" />
            <h3 className="text-lg sm:text-xl font-bold text-gray-900 mb-2">{title}</h3>
            <p className="text-sm sm:text-base text-gray-600 mb-4 flex-grow">{description}</p>
            <button
                onClick={handleLearnMore}
                className="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 sm:px-6 rounded-lg transition duration-300 text-sm sm:text-base"
            >
                Learn More
            </button>
        </div>
    )
}

const BlogCard: React.FC<BlogCardProps> = ({ image, title, tag, date, description, link }) => {


    return (
        <div className="bg-white shadow-md p-12 lg:p-12 flex flex-col h-full items-center text-center">
            <img src={image} alt={title} className="w-full h-40 lg:h-56 object-contain rounded-lg mb-4" />
            <p className="inline-block text-md text-blue-800 p-4 bg-gray-100 rounded-lg mb-6">{tag}</p>            <p className="text-md px-6 mb-3">🗓️ {date}</p>
            <h3 className="text-lg sm:text-xl font-bold text-gray-900 mb-2">{title}</h3>
            <p className="text-sm sm:text-base text-gray-600 mb-4 flex-grow">{description}</p>
            <Link
                to={link}
                className="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 sm:px-6 rounded-lg transition duration-300 text-sm sm:text-base"
            >
                Read More
            </Link>
        </div>
    )
}

interface AffiliationProps {
    name: string;
    logo: string;
}


const Home: React.FC = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();


    const affiliations: AffiliationProps[] = [
        { name: 'Fortis Healthcare Noida', logo: '/images/007.jpg' },
        { name: 'AIIMS', logo: '/images/006.jpg' },
        { name: 'BLK Super Speciality Hospital', logo: '/images/005.png' },
        { name: 'SMS Medical College', logo: '/images/010.jpg' },
        { name: 'Other Affiliation', logo: '/images/004.jpg' },
    ];


    const services = [
        {
            image: "/images/brain.png",
            title: "Brain",
            description: "Diseases and Injuries affecting and arising from the brain can be scary. We help by covering a wide spectrum of surgeries.",
            slug: 'brain-services'
        },
        {
            image: "/images/spine.png",
            title: "Spine",
            description: "Address issues related to spine and spinal cord pathologies and injuries with our minimally invasive surgeries that ensure precision and safety.",
            slug: "spine-services"
        },
        {
            image: "/images/nerve.png",
            title: "Nerves",
            description: "Treatment of nerve disorders , utilizing advanced techniques to manage conditions like sciatica, carpe tunnel",
            slug: "nerve-services"
        }
    ]

    const blogs = [
        {
            image: "/images/blog1.jpg",
            tag: "Disease",
            date: "14th June, 2024",
            title: "Understanding Complex Degenerative Spine Diseases",
            description: "Discover the latest advancements in treating complex degenerative spine diseases. Learn about innovative surgical techniques and the benefits they offer to patients.",
            link: "blogs/blog1"
        },
        {
            image: "images/blog2.jpg",
            tag: "Disease",
            date: "4th June, 2024",
            title: "Minimally Invasive Spine Surgery: What You Need to Know",
            description: "Minimally invasive spine surgery is revolutionizing patient care. Find out how these procedures work and what makes them a preferable option for many patients.",
            link: 'blogs/blog2'
        },
        {
            image: "images/blog3.jpg",
            tag: "Commentary",
            date: "10th June, 2024",
            title: "Advancements in Neurosurgert Technology",
            description: "Explore the cutting-edge technologies that are shaping the future of neurosurgery. From neuro-navigation systems to high-end imaging, see how these tools enhance surgical precision and outcomes.",
            link: 'blogs/blog3'
        }
    ]

    const handleSmoothScroll = (e: React.MouseEvent<HTMLAnchorElement>, target: string) => {

        e.preventDefault();
        const element = document.getElementById(target);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }


    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);



    return (
        <>
            <SEOHelmet />
            <main>
                <section id="home" className="relative h-screen">
                    <div
                        className="absolute inset-0 bg-cover bg-center align-items h-[100vh] z-0"
                        style={{
                            backgroundImage: "url('/images/home.jpg')",
                        }}
                    >
                        <div className="absolute inset-0"></div>
                    </div>
                    <div className="relative z-10 h-full flex flex-col justify-center items-center lg:items-start max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h1 className="text-4xl text-center sm:text-5xl md:text-6xl font-bold text-zinc-900 mb-4">
                            Dr. Gangesh Gunjan
                        </h1>
                        <h2 className="text-xl sm:text-2xl md:text-3xl text-black mb-2 max-w-2xl text-center lg:text-left">
                            MBBS (Hons.) (Gold Medalist), MS (Gen Surgery), M.Ch (Neurosurgery)
                        </h2>
                        <p className="text-sm lg:text-xl text-black mb-8 max-w-2xl text-center lg:text-left">
                            One of the best neurosurgeons in Delhi NCR. Trusted by hundreds of patients since 2010
                        </p>
                        <div className="flex flex-col md:flex-row">
                        <Link to="#contact"
                            className="mr-0 md:mr-6 lg:mr-12 bg-blue-800 hover:bg-white  text-white hover:text-blue-800 font-bold py-3 px-6 rounded-lg transition items-center text-center duration-300"
                            onClick={(e) => handleSmoothScroll(e, 'contact')}
                        >
                            Make Appointment
                        </Link>
                        <button
                            className="bg-white mt-8 md:mt-0 text-blue-800 hover:bg-blue-800 hover:text-white font-bold py-3 px-6 rounded-lg transition items-center text-center duration-300"
                            onClick={openModal}>
                            Online Appointment
                        </button>
                        </div>
                    </div>
                    <OnlineModal isOpen={isModalOpen} onClose={closeModal} />
                </section>
                <section id="about" className="relative py-16 bg-gray-50">
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex flex-col lg:flex-row items-center">
                            {/* Image */}
                            <div className="lg:w-1/2 mb-8 lg:mb-0">
                                <div className="rounded-lg overflow-hidden shadow-lg">
                                    <img
                                        src="/images/about.png"
                                        alt="Dr. Gangesh Gunjan"
                                        className="w-full h-auto object-cover"
                                    />
                                </div>
                            </div>

                            {/* Text Content */}
                            <div className="lg:w-1/2 lg:pl-12 items-center text-center">
                                <h2 className="text-3xl font-bold text-gray-900 mb-4">
                                    Specialised Neurosurgical Care
                                </h2>
                                <p className="text-gray-600 mb-4">
                                    With experience of more than a decade, Dr Gangesh Gunjan currently
                                    practices as a consultant neurosurgeon at Fortis Hospital in Noida. Before that,
                                    stints at BLK Hospital in Delhi and SMS Medical College and Hospital in Jaipur
                                    added to his clinical and surgical skills. Hands-on training and education at
                                    India's top institutes reflects in his precision and experience, which is
                                    absolutely necessary for unparalleled care in spine and neurosurgery.
                                </p>
                                <p className="text-gray-600 mb-6">
                                    The combination of traditional techniques, experienced hands and state-of-
                                    the-art technology is the reason hundreds of patients trust Dr Gunjan's
                                    surgical skills.
                                </p>
                                <button
                                    className="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-lg transition duration-300"
                                    onClick={() => navigate('/about')}
                                >

                                    Know More
                                </button>
                            </div>
                        </div>

                        {/* Affiliations */}
                        <div className="mt-16">
                            <h2 className="text-3xl md:text-5xl font-semibold text-center text-gray-900 mb-16">
                                AFFILIATIONS
                            </h2>
                            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
                                {affiliations.map((affiliation, index) => (
                                    <div key={index} className="flex items-center justify-center p-4 bg-white rounded-[30px] shadow-md">
                                        <img
                                            src={affiliation.logo}
                                            alt={affiliation.name}
                                            className="max-w-full max-h-36 object-contain"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
                {/* services section */}
                <section id="services" className="py-12 px-4 md:px-6 lg:px-8">
                    <h2 className="text-3xl md:text-5xl font-semibold text-center text-gray-900 mb-16">
                        Our Services
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {services.map((service, index) => (
                            <ServiceCard key={index} {...service} />
                        ))}
                    </div>
                </section>

                {/* blog section */}
                <section id="blog" className="py-12 px-4 lg:px-8 bg-white/90">
                    <h2 className="text-3xl  md:text-5xl font-semibold text-center text-gray-900 mb-16">Our Blog</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {blogs.map((blog, index) => (
                            <BlogCard key={index} {...blog} />
                        ))}
                    </div>
                </section>
                <section id="contact">
                    <BookAppointment />
                </section>

                    <Testimonials />

                <section id="location" className="py-12 px-4 lg:px-8 bg-gray-200">
                    <h2 className="text-3xl md:text-5xl font-semibold text-center text-gray-900 mb-16">Our Location</h2>
                    <div className="max-w-screen-2xl mx-auto">
                        <div className="aspect-w-21 aspect-h-9  h-[350px] lg:h-[600px]">
                            <iframe
                                className="w-full h-full"
                                title="Google Map"
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d448303.20158914855!2d77.372583!3d28.618708000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef719cf3f0b3%3A0xc24d4fda4fdca748!2sFortis%20Hospital%20Noida!5e0!3m2!1sen!2sin!4v1724735418682!5m2!1sen!2sin"
                                style={{ border: "0" }}
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </section>

            </main>
        </>
    )
}

export default Home;